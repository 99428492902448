import { useEffect, useRef } from 'react'
import { useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit'

const IDLE_TIMEOUT = 10 * 60 * 1000 //10 minutes

export default function useAutoLogout () {
  const user = useAuthUser()
  const isAuthenticated = useIsAuthenticated()
  const signOut = useSignOut()

  const tokenExpiration = useAuthUser()
  const idleTimeoutRef = useRef(null)
  const expirationCheckIntervalRef = useRef(null)
  useEffect(() => {

    if (isAuthenticated()) {
      const expirationTime = Date.now() + tokenExpiration().expiresIn * 1000
      const handleLogout = () => {
        signOut()
        setTimeout(() => {
          window.location.href = '/login'
        }, 1000)
      }
      const checkExpiration = () => {
        if (Date.now() >= expirationTime) {
          console.log('Token expired. Logging out')
          handleLogout()
        }
      }

      const resetIdleTimer = () => {
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
        idleTimeoutRef.current = setTimeout(handleLogout, IDLE_TIMEOUT)
      }
      resetIdleTimer()
      document.addEventListener('mousemove', resetIdleTimer)
      document.addEventListener('keydown', resetIdleTimer)
      expirationCheckIntervalRef.current = setInterval(checkExpiration, 1000)
      return () => {
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
        if (expirationCheckIntervalRef.current) {
          clearInterval(expirationCheckIntervalRef.current)
        }
        document.removeEventListener('mousemove', resetIdleTimer)
        document.removeEventListener('keydown', resetIdleTimer)
      }
    }
  }, [isAuthenticated()])
}
