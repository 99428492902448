import React, { useEffect, useState } from 'react'
import { useAuthUser, useAuthHeader } from 'react-auth-kit'
import { fetchLeave, fetchLeaveTypes } from '../../service/leaveService'
import { useGridApiRef } from '@mui/x-data-grid'
import TableComponent from '../TableComponents'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-in'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { confirmAlert } from 'react-confirm-alert'
import '../../stylings/react-confirm-customalert.css'
import {
  useGetAttendanceDataQuery,
  useGetLeaveDataQuery,
  useUpdateLeaveMutation
} from '../../reduxstore/features/attendanceRTKFile'
import { useGetHolidaysDataQuery } from '../../reduxstore/features/holidaysRTKFile'

export default function UserLeaveRequests ({ refreshTable }) {
  dayjs.extend(timezone)
  const user = useAuthUser()
  const apiRef = useGridApiRef()
  const itemName = 'leave'
  const itemID = 'employeeLeaveID'
  const [attendance, setAttendance] = useState([])
  const [holidays, setHolidays] = useState([])
  const auth = useAuthHeader()
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const deleteDisable = row =>
    row.approved == 1 || row.onceApproved == 1 || row.approved == 0
  const editDisable = row => row.approved == 0
  const handleDateMismatch = () => {
    confirmAlert({
      title: 'Improper dates-Set dates properly before confirming edit',
      buttons: [
        {
          label: 'OK'
        }
      ]
    })
  }
  const {
    data = [],
    isError,
    isLoading
  } = useGetLeaveDataQuery({
    employeeID: user().employeeID
  })
  const { data: attendanceData = [] } = useGetAttendanceDataQuery({
    employeeID: user().employeeID,
    auth: auth()
  })
  const {
    data: holidaylist = [],
    isError: holidaysError,
    isLoading: holidaysLoading
  } = useGetHolidaysDataQuery({
    employeeID: user().employeeID,
    auth: auth()
  })
  const [updateLeave] = useUpdateLeaveMutation()

  useEffect(() => {
    setAttendance(attendanceData)
    setHolidays(holidaylist)

  }, [])

  const columns = [
    {
      field: 'leaveType',
      headerName: 'Leave Type',
      width: 100,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          value={params.value}
          key={'leaveID'}
          keyvalue={'leaveType'}
          label={params.headerName}
          fetchData={fetchLeaveTypes}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'reason',
      headerName: 'Reason for Request',
      width: 200,
      sortable: false,
      filterable: false,
      editable: true,
      renderEditCell: params => (
        <GenericSelectCell
          itemName={'leavereasons'}
          value={params.value}
          key={'leaveReasonID'}
          keyvalue={'leaveReason'}
          label={params.headerName}
          onChange={async event => {
            await apiRef.current.setEditCellValue({
              id: params.id,
              field: params.field,
              value: event
            })
          }}
        />
      )
    },
    {
      field: 'leaveStartDate',
      headerName: 'Start Date',
      type: 'date',
      valueGetter: value =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format='DD/MM/YYYY'
            value={dayjs(params.value)}
            shouldDisableDate={date =>
              dayjs(date).day() % 6 == 0 ||
              (holidaylist.length > 0
                ? holidaylist.some(h => dayjs(h.holiday).isSame(date, 'date'))
                : false) ||
              (attendanceData.length > 0
                ? attendanceData.some(
                    a => a.leave && dayjs(a.date).isSame(date, 'date')
                  )
                : false)
            }
            onChange={async event => {
              console.log(
                holidaylist
              )
              if (
                dayjs(event).isAfter(
                  dayjs(apiRef.current.getCellValue(params.id, 'leaveEndDate')),
                  'day'
                )
              )
                handleDateMismatch()
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      ),
      width: 150,
      editable: true
    },
    {
      field: 'leaveEndDate',
      headerName: 'End Date',
      type: 'date',
      valueGetter: value =>
        value !== null ? dayjs(value).tz('Asia/Kolkata').toDate() : null,
      renderEditCell: params => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(params.value)}
            format='DD/MM/YYYY'
            shouldDisableDate={date =>
              dayjs(date).day() % 6 == 0 ||
              (holidaylist.length > 0
                ? holidaylist.some(h => dayjs(h.holiday).isSame(date, 'date'))
                : false) ||
              (attendanceData.length > 0
                ? attendanceData.some(
                    a => a.leave && dayjs(a.date).isSame(date, 'date')
                  )
                : false)
            }
            onChange={async event => {
              if (
                dayjs(event).isBefore(
                  dayjs(
                    apiRef.current.getCellValue(params.id, 'leaveStartDate')
                  ),
                  'day'
                )
              )
                handleDateMismatch()
              console.log('Date changed to:', dayjs(event))
              await apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: dayjs(event).tz('Asia/Kolkata').toDate()
              })
            }}
          />
        </LocalizationProvider>
      ),
      width: 150,
      editable: true
    },

    {
      field: 'approved',
      headerName: 'Status',
      valueGetter: value =>
        value == null ? 'Pending' : value == 0 ? 'Denied' : 'Approved',
      width: 100
    },
    {
      field: 'daysOff',
      headerName: 'Days',
      width: 50
    },
    {
      field: 'comments',
      headerName: 'Comment',
      width: 200,
      sortable: false,
      filterable: false,
      editable: true
    },
    {
      field: 'managerRemark',
      headerName: 'Remarks',
      sortable: false,
      filterable: false,
      width: 200
    }
  ]
  return (
    <TableComponent
      customRows={data}
      refreshTable={refreshTable}
      columns={columns}
      apiRef={apiRef}
      itemName={itemName}
      itemID={itemID}
      keepCheckBox={false}
      intState={{
        sorting: {
          sortModel: [{ field: 'leaveStartDate', sort: 'desc' }]
        }
      }}
      updateMutation={updateLeave}
      deleteDisableCondition={deleteDisable}
      editDisableCondition={editDisable}
    />
  )
}
