import React, { useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import TableComponent from '../TableComponents'
import 'dayjs/locale/en-in'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import '../../stylings/react-confirm-customalert.css'
import {
  approveTimesheetAndSendToAdmin,
  bulkApproveTimesheetsForAssignedAndSendToAdmin,
  getTeamTimesheet,
  sendBackToEmployee
} from '../../service/timesheetservice'
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Typography
} from '@mui/material'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { fetchShortUserInfo } from '../../service/addUserService'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import { useAlert } from 'react-alert'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { dataGridStyles } from '../../stylings/datagridStyles'

export default function ManagerTimesheet ({ refreshTable }) {
  dayjs.extend(timezone)
  const [onGenerateSheet, setOnGenerateSheet] = useState(false)
  const [employee_id, setEmployee_id] = useState(null)
  const user = useAuthUser()
  const apiRef = useGridApiRef()
  const alert = useAlert()
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [progress, setProgress] = useState(false)
  const [userTimesheet, setUserTimesheet] = useState([])
  const [month, setMonth] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const [bulkMonth, setBulkMonth] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  )

  const today = new Date()
  const rowColourConditions = row =>
    row.fullDayLeave
      ? 'rowLeaveColour'
      : row.holidayOccasion != null
      ? 'rowHolidayColour'
      : ''

  const handleFetchReport = () => {
    setOnGenerateSheet(true)
  }

  useEffect(() => {
    setOnGenerateSheet(false)
    fetchData()
  }, [employee_id, month])

  async function fetchData () {
    try {
      setProgress(true)
      const response = await getTeamTimesheet(
        user().team,
        employee_id,
        dayjs(month).format('YYYY-MM-DD')
      )
      const data = await response.json()
      setUserTimesheet(data)
    } catch (error) {
      console.error(error)
    } finally {
      setProgress(false)
    }
  }

  const approveByManager = async () => {
    try {
      setProgress(true)
      const data = await approveTimesheetAndSendToAdmin(employee_id, month)
      const msg = await data.text()
      alert.show(msg)
    } catch {
      alert.error('Error in approval')
    } finally {
      fetchData()
      setRowSelectionModel([])
    }
  }

  const resendForReview = async () => {
    if (rowSelectionModel.length > 0) {
      try {
        setProgress(true)
        for (const timesheetID of rowSelectionModel) {
          const selectedRequest = userTimesheet.find(
            att => att.timesheetID === timesheetID
          )
          if (selectedRequest.approvalByManagerCount < 1) {
            const res = await sendBackToEmployee(
              selectedRequest.timesheetID,
              selectedRequest.managerComments
            )
            const restext = await res.text()
            alert.show(restext)
          } else {
            alert.show(
              selectedRequest.attendanceDate + ' has already been approved'
            )
          }
        }
      } catch (error) {
        console.error(error)
        alert.error('Error has occurred')
      } finally {
        fetchData()
        setProgress(false)
        setRowSelectionModel([])
      }
    } else alert.show('Select rows before sending back for review')
  }

  const renderManagerRemarksCell = params => {
    const row = params.row
    return (
      <TextField
        fullWidth
        type='text'
        value={row.managerComments}
        onChange={event => handleManagerRemarksChange(event, row.timesheetID)}
      />
    )
  }

  const bulkApproveByManager = async () => {
    try {
      setProgress(true)
      const data = await bulkApproveTimesheetsForAssignedAndSendToAdmin(user().employeeID, bulkMonth)
      const msg = await data.text()
      alert.show(msg)
    } catch {
      alert.error('Error in approval')
    } finally {
      fetchData()
      setRowSelectionModel([])
    }
  }

  const columns = [
    {
      field: 'attendanceDate',
      headerName: 'Date',
      width: 100,
      editable: false,
      valueGetter: value =>
        value !== null
          ? dayjs(dayjs(value).tz('Asia/Kolkata').toDate()).format('DD/MM/YYYY')
          : null
    },
    {
      field: 'approvalByManagerCount',
      headerName: 'Status',
      width: 200,
      valueGetter: value =>
        value == -1
          ? 'Not approved'
          : value === 0
          ? 'Pending approval'
          : 'Approved By Manager'
    },

    {
      field: 'shiftName',
      headerName: 'Shift',
      width: 100,
      colSpan: (value, row) =>
        row.fullDayLeave || row.holidayOccasion ? 6 : 1,
      valueGetter: (row, value) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion)
          ? row?.fullDayLeave
            ? 'LEAVE'
            : row?.holidayOccasion
          : row.shiftName,
      renderCell: ({ row, value }) =>
        row.fullDayLeave || row.holidayOccasion ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '110%'
            }}
          >
            {row.fullDayLeave ? 'LEAVE' : row.holidayOccasion}
          </div>
        ) : (
          row.shiftName
        )
    },
    {
      field: 'shiftStartTime',
      headerName: 'Shift Start',
      width: 100,
      valueGetter: (value, row) => {
        return Boolean(row?.fullDayLeave || row?.holidayOccasion)
          ? null
          : value.toString()
      }
    },
    {
      field: 'shiftEndTime',
      headerName: 'Shift End',
      width: 100,
      valueGetter: (value, row) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion)
          ? null
          : row.shiftEndTime
    },
    {
      field: 'attendanceTime',
      headerName: 'Attendance Logged Time',
      width: 200,
      type: 'string',
      valueGetter: (value, row) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion) ? null : value
    },
    {
      field: 'workingDayType',
      headerName: 'Day Type',
      width: 100,
      valueGetter: (value, row) =>
        Boolean(row?.fullDayLeave || row?.holidayOccasion) ? null : value
    },
    {
      field: 'workedOnProjects',
      headerName: 'Project',
      width: 300,
      sortable: false,
      filterable: false,
      editable: false,
      valueGetter: (value, row) =>
        Boolean(row.fullDayLeave || row.holidayOccasion) ? null : value
    },
    {
      field: 'managerComments',
      headerName: 'Remarks',
      sortable: false,
      filterable: false,
      renderCell: renderManagerRemarksCell,
      width: 300
    },
    {
      field: 'userComments',
      headerName: 'Employee Comments',
      sortable: false,
      filterable: false,
      width: 200
    }
  ]

  const handleManagerRemarksChange = (event, timesheetID) => {
    const index = userTimesheet.findIndex(
      request => request.timesheetID === timesheetID
    )
    if (index !== -1) {
      const updatedRequests = [...userTimesheet]
      updatedRequests[index].managerComments = event.target.value
      setUserTimesheet(updatedRequests)
    }
  }

  return (
    <>
      <div>
        <Typography variant='h4'>Single Approval</Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <FormControl sx={{ width: '250px' }}>
            <InputLabel id='employee-select-label'>Employee</InputLabel>
            <GenericSelectCell
              label={'Employee'}
              value={employee_id}
              key={'employeeID'}
              keyvalue={'employeeID'}
              itemName={'user'}
              fetchData={fetchShortUserInfo}
              extra={['name', 'lname']}
              onChange={newValue => setEmployee_id(newValue)}
            />
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ margin: '20px' }}>
              <DatePicker
                format='MM'
                views={['month', 'year']}
                label='Month'
                value={dayjs(month)}
                minDate={dayjs(today).subtract(1, 'months')}
                maxDate={dayjs(today)}
                onChange={newVal =>
                  setMonth(dayjs(newVal).format('YYYY-MM-DD'))
                }
              />
            </div>
          </LocalizationProvider>
          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleFetchReport}
            sx={{ margin: '20px' }}
          >
            Fetch Timsheet
          </Button>
        </div>
        {onGenerateSheet ? (
          <>
            <>
              <DataGrid
                disableRowSelectionOnClick
                checkboxSelection
                autoHeight
                rows={userTimesheet}
                columns={columns}
                getRowId={row => row.timesheetID}
                apiRef={apiRef}
                sx={dataGridStyles}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={newRowSelectionModel =>
                  setRowSelectionModel(newRowSelectionModel)
                }
                getRowClassName={params => {
                  return rowColourConditions(params.row)
                }}
                showCellVerticalBorder
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'attendanceDate', sort: 'asc' }]
                  },
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: 5
                    }
                  }
                }}
                pageSizeOptions={[5, 10, 15, 20, 100]}
                slots={{
                  toolbar: CustomGridToolbarNoAdd
                }}
              />
              <Button
                variant='contained'
                className='button-gradient'
                onClick={approveByManager}
                sx={{ margin: '1%' }}
              >
                Submit Entire Timesheet
              </Button>
              <Button
                variant='outlined'
                color='error'
                onClick={resendForReview}
              >
                Send Rows Back For Review
              </Button>
            </>
            <Backdrop
              sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
              open={progress}
            >
              <CircularProgress></CircularProgress>
            </Backdrop>
          </>
        ) : null}
        <div>
          <Typography variant='h4'>Bulk Approval</Typography>
          <Typography variant='body1'>
            CAUTION: This will approve timesheets for all employees assigned to
            you who have submitted for the selected month - sending rows back
            for review is not possible after bulk approval
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div>
                <DatePicker
                  format='MM'
                  views={['month', 'year']}
                  label='Month'
                  value={dayjs(month)}
                  minDate={dayjs(today).subtract(1, 'months')}
                  maxDate={dayjs(today)}
                  onChange={newVal =>
                    setBulkMonth(dayjs(newVal).format('YYYY-MM-DD'))
                  }
                />
              </div>
            </LocalizationProvider>
            <Button
              variant='contained'
              className='button-gradient'
              sx={{ margin: '20px' }}
              onClick={bulkApproveByManager}
            >
              Bulk Approve
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
