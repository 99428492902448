import { Paper, Tabs, Tab, Typography, Grid } from '@mui/material'
import { CustomTab } from '../stylings/customtabstyle'
import React, { useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { pageStyle } from '../stylings/pageStyle'
import DeviceAssetUserTable from '../components/Assets/DeviceAssetUserTable'
import HardwareUserTable from '../components/Assets/HardwareUserTable'
import LaptopAssetUserTable from '../components/Assets/LaptopAssetUserTable'
export default function AssetUserBoard () {
  const [value, setValue] = useState(0)
  const user = useAuthUser()
  const [modules, setModules] = useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    if (user() && user().modules) {
      setModules(user().modules.map(p => p.name))
    }
  }, [user()])

  return modules.includes('ATTENDANCE_MODULE') ? (
    <Grid container columns={20} style={pageStyle}>
      <Grid item xs={20} md={20} lg={20} xl={20}>
        <>
          <Tabs
            indicatorColor='secondary'
            value={value}
            onChange={handleChange}
          >
            <CustomTab
              label={<Typography variant='h6'>Laptop Asset</Typography>}
            />
            <CustomTab
              label={<Typography variant='h6'>Mobile Asset</Typography>}
            />

            <CustomTab label={<Typography variant='h6'>Hardware</Typography>} />
          </Tabs>
          {value === 0 && <LaptopAssetUserTable/>}
          {value === 1 && <DeviceAssetUserTable />}
          {value === 2 && <HardwareUserTable/>}
        </>
      </Grid>
    </Grid>
  ) : null
}
