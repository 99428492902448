import React, { useState, useEffect } from 'react'
import { getAllLeaveReports } from '../../service/reportsService'
import { CircularProgress, Backdrop } from '@mui/material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import CustomGridToolbarNoAdd from '../Admin/CustomGridToolbarNoAdd'
import { dataGridStyles } from '../../stylings/datagridStyles'
import dayjs from 'dayjs'
import { name } from 'pubsub-js'
import CustomGridToolbarWithSearchOnly from '../Admin/CustomGridToolBarWithSearchOnly'
export default function LeaveReport ({
  startDate,
  endDate,
  department,
  team,
  employee_id,
  shift,
  refreshTable,
  visibleColumns,
  rows,
  onlySearch
}) {
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [progress, setProgress] = useState(false)
  const [items, setItems] = useState([])
  const [column, setColumn] = useState([])
  const apiRef = useGridApiRef()
  async function fetchData () {
    try {
      setProgress(true)
      const res = await getAllLeaveReports({
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        team: team,
        department: department,
        empID: employee_id,
        shift: shift
      })
      const data = await res.json()
      setItems(data)
    } catch (error) {
      console.error(error)
      setItems(null)
    } finally {
      setProgress(false)
    }
  }
  const columns = [
    {
      field: 'employeeID',
      headerName: 'Employee ID',
      width: '150'
    },
    {
      field: 'name',
      headerName: 'First Name',
      width: '150'
    },
    {
      field: 'lname',
      headerName: 'Last Name',
      width: '150'
    },
    {
      field: 'department',
      headerName: 'Department',
      width: '150'
    },
    {
      field: 'team',
      headerName: 'Team',
      width: '150'
    },
    {
      field: 'shift',
      headerName: 'Shift',
      width: '150'
    },
    {
      field: 'office',
      headerName: 'Office Location',
      width: '150'
    },
    {
      field: 'isApproved',
      headerName: 'Status',
       valueGetter: (value) =>
        value == null ? 'Pending' : value == 0 ? 'Denied' : 'Approved',
      width: '150'
    },
    {
      field: 'leaveStartDate',
      headerName: 'Start Date',
       valueGetter: (value) =>
        value !== null
          ? dayjs(value).tz('Asia/Kolkata').format('DD/MM/YYYY')
          : null,

      width: '150'
    },
    {
      field: 'leaveEndDate',
       valueGetter: (value) =>
        value !== null
          ? dayjs(value).tz('Asia/Kolkata').format('DD/MM/YYYY')
          : null,
      headerName: 'End Date',
      width: '150'
    },
    {
      field: 'hoursOff',
      headerName: 'Hours',
      width: '150'
    },
    {
      field: 'daysOff',
      headerName: 'Days',
      width: '150'
    },
    {
      field: 'leaveType',
      headerName: 'Leave Type',
      width: '150'
    },
    {
      field: 'reason',
      headerName: 'Reason',
      width: '200'
    },
    {
      field: 'approvedBy',
      headerName: 'Approved By',
      width: '150'
    },
    {
      field: 'approvedDate',
      headerName: 'Approved Date',
       valueGetter: (value) =>
        value !== null
          ? dayjs(value).tz('Asia/Kolkata').format('DD/MM/YYYY')
          : null,
      width: '150'
    }
  ]
  useEffect(() => {
    if (user() && isAuthenticated()) {
      fetchData()
    }
  }, [refreshTable, startDate, endDate, employee_id, department, team, shift])


  return (
    <>
      <>
        <DataGrid
          disableRowSelectionOnClick
          disableVirtualization
          columnVisibilityModel={visibleColumns}
          getRowId={row => row['EmployeeLeaveID']}
          loading={progress}
          rows={rows!=null?rows:items}
          columns={columns}
          apiRef={apiRef}
          sx={dataGridStyles}
          showCellVerticalBorder
          
          showColumnVerticalBorder
          autoHeight
          slots={{
            toolbar: onlySearch ? CustomGridToolbarWithSearchOnly:
            CustomGridToolbarNoAdd
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[5, 10, 15, 20, 100]}
        />
      </>

    </>
  )
}
