import { configureStore } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'
import rootReducer from './root'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/es/stateReconciler/hardSet'
import { attendanceAndLeaveAPIs, attendanceAPIs } from './features/attendanceRTKFile'
import { holidaysAPIs } from './features/holidaysRTKFile'
import leaveReportViewStatusSlice from './features/leaveReportViewStatusSlice'
// const persistConfig = {
//   key: 'root',
//   storage: storage,
//   whitelist: ['root', 'rootReducer'],
//   stateReconciler: hardSet
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(
      attendanceAndLeaveAPIs.middleware,
      holidaysAPIs.middleware,
    )
  }
})
//export const persistor = persistStore(store)
export default store
