/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { CancelOutlined } from '@mui/icons-material'
import { Formik } from 'formik'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as yup from 'yup'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import dayjs from 'dayjs'
import { fetchShortUserInfo } from '../../../service/addUserService'
import { addItem } from '../../../service/InterfaceFunctions/addItem'
import { useAlert } from 'react-alert'

export default function CreateLaptopForm ({
  open,
  handleClose,
  setRefreshTable
}) {
  const alert = useAlert()

  const handleFormClose = () => {
    handleClose()
  }

  const handleValueSubmit = async e => {
    try {
      const res = await addItem('laptopasset', e)
      const data = await res.text()
      if(res.ok)
       alert.show(data)
      else
       alert.show('Error in creation, ensure IDs are unique')
    } catch (error) {
      console.log(error)
      alert.show('Error')
    } finally {
      setRefreshTable(true)
      handleFormClose()
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton onClick={handleFormClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
        Create Laptop
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            inUse: false,
            laptopAssetID: null,
            modelName: null,
            employeeIDFk: null,
            imeicode: null,
            serialNo: null,
            accessories: null,
            processor: null,
            baseboardSerialNo: null,
            baseboardVersion: null,
            brand:null,
            productID: null,
            batteries: null,
            ram: null,
            storage: null,
            diskDrives: null,
            osName: null,
            warranty: null,
            screenSize: null,
            charlesID: null,
            charlesKey: null,
            msofficeKey: null,
            msofficeUsername: null,
            msofficePassword: null,
            purchaseDate: dayjs(new Date()),
            modelNo: null
          }}
          onSubmit={values => handleValueSubmit(values)}
          validationSchema={yup.object({
            laptopAssetID: yup.string().trim().min(7).required('Required'),
            serialNo: yup.string().trim().min(2).required('Required'),
            purchaseDate: yup.date().nonNullable().required('Required'),
            modelName: yup.string().trim().min(1).required('Required'),
            brand: yup.string().trim().min(1).required('Required'),
            osName:yup.string().trim().min(1).required('Required'),
            warranty: yup.string().trim().min(1).required('Required'),
            processor:yup.string().trim().min(1).required('Required')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              isValid,
              submitCount
            } = props
            return (
              <form className='root' noValidate onSubmit={handleSubmit}>
                <TextField
                  id='startingID-input'
                  label='Asset ID'
                  variant='outlined'
                  fullWidth
                  value={values.laptopAssetID}
                  required={true}
                  error={touched.laptopAssetID && errors.laptopAssetID}
                  helperText={'Minimum 7 characters'}
                  onChange={handleChange('laptopAssetID')}
                  style={{ margin: '20px auto' }}
                />

                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={values.inUse}
                      required={true}
                      error={touched.inUse && errors.inUse}
                      onChange={newVal =>
                        setFieldValue('inUse', newVal.target.checked)
                      }
                    />
                  }
                  labelPlacement='start'
                  label='In Use'
                  style={{ marginLeft: '0px' }}
                />
                <TextField
                  id='modelName-input'
                  label='Model Name'
                  variant='outlined'
                  fullWidth
                  required
                  error={touched.modelName && errors.modelName}
                  value={values.modelName}
                  onChange={handleChange('modelName')}
                  style={{ margin: '20px auto' }}
                />

                <TextField
                  id='brand-input'
                  label='Brand'
                  variant='outlined'
                  fullWidth
                  required
                  error={touched.brand && errors.brand}
                  value={values.brand}
                  onChange={handleChange('brand')}
                  style={{ margin: '20px auto' }}
                />

                <FormControl
                  fullWidth
                  style={{ margin: '20px auto' }}
                  error={touched.purchaseDate && errors.purchaseDate}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Purchase Date'
                      value={dayjs(values.purchaseDate)}
                      format='DD/MM/YYYY'
                      onChange={val =>
                        setFieldValue('purchaseDate', dayjs(val))
                      }
                      slotProps={{
                        textField: {
                          error:
                            touched.purchaseDate &&
                            errors.purchaseDate &&
                            values.purchaseDate != null
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth style={{ margin: '20px auto' }}>
                  <InputLabel id='emp_ID-select-label'>Assign To</InputLabel>
                  <GenericSelectCell
                    label={'Assign To'}
                    value={values.employeeIDFk}
                    itemName={'user'}
                    key={'userID'}
                    keyvalue={'employeeID'}
                    fetchData={fetchShortUserInfo}
                    extra={['name', 'lname']}
                    onChange={handleChange('employeeIDFk')}
                  />
                </FormControl>
                <TextField
                  id='imeiCode-input'
                  label='IMEI Code'
                  variant='outlined'
                  fullWidth
                  value={values.imeicode}
                  onChange={handleChange('imeicode')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  id='serialNo-input'
                  label='Serial No'
                  variant='outlined'
                  fullWidth
                  value={values.serialNo}
                  required={true}
                  error={touched.serialNo && errors.serialNo}
                  onChange={handleChange('serialNo')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Product ID'
                  variant='outlined'
                  fullWidth
                  value={values.productID}
                  onChange={handleChange('productID')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Storage'
                  variant='outlined'
                  fullWidth
           //       required
           //       error={touched.storage && errors.storage}
                  value={values.storage}
                  onChange={handleChange('storage')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Warranty'
                  variant='outlined'
                  fullWidth
                  required
                  error={touched.warranty && errors.warranty}
                  value={values.warranty}
                  onChange={handleChange('warranty')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Screen Size'
                  variant='outlined'
                  fullWidth
                  value={values.screenSize}
                  onChange={handleChange('screenSize')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Processor'
                  variant='outlined'
                  fullWidth
                  required
                  error={touched.processor && errors.processor}
                  value={values.processor}
                  onChange={handleChange('processor')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='RAM'
                  variant='outlined'
                  fullWidth
                //  required
                //  error={touched.ram && errors.ram}
                  value={values.ram}
                  onChange={handleChange('ram')}
                  style={{ margin: '20px auto' }}
                />

                <TextField
                  label='OS'
                  variant='outlined'
                  fullWidth
                  required
                  error={touched.osName && errors.osName}
                  value={values.osName}
                  onChange={handleChange('osName')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Baseboard Number'
                  variant='outlined'
                  fullWidth
                  //required
                 // error={touched.baseboardSerialNo && errors.baseboardSerialNo}
                  value={values.baseboardSerialNo}
                  onChange={handleChange('baseboardSerialNo')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Baseboard Version'
                  variant='outlined'
                  fullWidth
                  //required
                  //error={touched.baseboardVersion && errors.baseboardVersion}
                  value={values.baseboardVersion}
                  onChange={handleChange('baseboardVersion')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Batteries'
                  variant='outlined'
                  fullWidth
                 // required
                 // error={touched.batteries && errors.batteries}
                  value={values.batteries}
                  onChange={handleChange('batteries')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Disk Drives'
                  variant='outlined'
                  fullWidth
                 // required
                 // error={touched.diskDrives && errors.diskDrives}
                  value={values.diskDrives}
                  onChange={handleChange('diskDrives')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Charles ID'
                  variant='outlined'
                  fullWidth
                  value={values.charlesID}
                  onChange={handleChange('charlesID')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='Charles Key'
                  variant='outlined'
                  fullWidth
                  value={values.charlesKey}
                  onChange={handleChange('charlesKey')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='MSOffice Key'
                  variant='outlined'
                  fullWidth
                  value={values.msofficeKey}
                  onChange={handleChange('msofficeKey')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='MSOffice Username'
                  variant='outlined'
                  fullWidth
                  value={values.msofficeUsername}
                  onChange={handleChange('msofficeUsername')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  label='MSOffice Password'
                  variant='outlined'
                  fullWidth
                  value={values.msofficePassword}
                  onChange={handleChange('msofficePassword')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  id='accessories-input'
                  label='Accessories'
                  variant='outlined'
                  fullWidth
                  value={values.accessories}
                  onChange={handleChange('accessories')}
                  style={{ margin: '20px auto' }}
                />
                <TextField
                  id='modelNo-input'
                  label='Additional Info'
                  variant='outlined'
                  fullWidth
                  value={values.modelNo}
                  onChange={handleChange('modelNo')}
                  style={{ margin: '20px auto' }}
                />
                {!isValid && submitCount > 0 && (
                  <div style={{ color: 'red', fontStyle: 'bold' }}>
                    Some fields may be empty or invalid
                  </div>
                )}
                <Button
                  variant='contained'
                  type='submit'
                  className='button-gradient'
                  style={{ margin: '20px' }}
                >
                  Submit
                </Button>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
