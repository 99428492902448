/*For use of BeQiSoft Pvt Ltd. */

import React, { useState, useEffect } from 'react'

import {
  Button,
  FormControl,
  InputLabel,
  Tabs,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Backdrop,
  CircularProgress
} from '@mui/material'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { addUser, bulkeditusers } from '../../../service/addUserService'
import RoleSelect from '../AdminSelectCells/RoleSelect'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import ManagerSelect from '../AdminSelectCells/ManagerSelect'
import ProjectSelect from '../AdminSelectCells/ProjectSelect'
import 'react-phone-number-input/style.css'
import { CancelOutlined, Save } from '@mui/icons-material'
import { useAlert } from 'react-alert'
import HolidayListSelect from '../AdminSelectCells/HolidayListSelect'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en-in'
import { CustomTab } from '../../../stylings/customtabstyle'
dayjs.extend(utc)
dayjs.extend(timezone)
export default function BulkEditUserForm ({
  open,
  handleClose,
  setRefreshTable,
  selectedRows
}) {
  const [managerID, setManagerID] = useState(null)
  const [team, setTeam] = useState(null)
  const [roles, setRoles] = useState([])
  const [department, setDepartment] = useState(null)
  const [designation, setDesignation] = useState(null)
  const [office, setOffice] = useState(null)
  const [shift, setShift] = useState(null)
  const [type, setType] = useState(null)
  const [assignedProjects, setAssignedProjects] = useState([])
  const [assignedHolidayList, setAssignedHolidayList] = useState([])
  const [value, setValue] = useState(0)
  const [doJ, setDoj] = useState(null)
  const [mandatoryIsEmpty, setMandatoryIsEmpty] = useState(false)
  const [progress, setProgress] = useState(false)
  const alert = useAlert()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const resetState = () => {
    setMandatoryIsEmpty(false)
    setTeam(null)
    setManagerID(null)
    setRoles([])
    setOffice(null)
    setDepartment(null)
    setDesignation(null)
    setShift(null)
    setType(null)
    setAssignedProjects([])
    setAssignedHolidayList([])
    setDoj(null)
    setValue(0)
  }
  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open])

  const handleCloseForm = () => {
    setMandatoryIsEmpty(false)
    setTeam('')
    setManagerID('')
    setRoles([])
    setOffice('')
    setDepartment('')
    setDesignation('')
    setShift('')
    setType('')
    setAssignedProjects([])
    setAssignedHolidayList([])
    setDoj(null)
    handleClose()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if(selectedRows.length == 0)
    {
      alert.error('No users selected')
      handleCloseForm()
      return 
    }
    const userData = {
      userIds: selectedRows,
      user: {
        type,
        department,
        designation,
        team,
        managerID,
        roles,
        shift,
        office,
        assignedProjects,
        assignedHolidayList,
        doJ
      }
    }
    {
      try {
        setProgress(true)
        const response = await bulkeditusers(userData)
        const responsePayload = await response.text()
        if (response.ok) alert.success(responsePayload)
      } catch (error) {
        alert.error('Bulk user updation failed')
        setProgress(false)
        console.log(error)
      } finally {
        setTimeout(() => {
          handleCloseForm()
          setMandatoryIsEmpty(false)
          setRefreshTable(true)
          setProgress(false)
        }, 1000)
      }
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <PersonAddAltIcon /> Bulk Edit users
        <IconButton onClick={handleCloseForm} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
    
      {<DialogContent>
        <p>
          Bulk editing will override the existing values of the selected users.
          Please note leaving these values blank will result in retaining of the
          existing previous values. Only modify values that will need to be the
          same for all the selected users{' '}
        </p>
        <Tabs value={value} onChange={handleChange} indicatorColor='secondary'>
          <CustomTab
            label={<Typography variant='h6'>Official Details</Typography>}
          />
        </Tabs>
        <form className='div-centerstyle' noValidate>

          {value === 0 && (
            <>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Date of Joining'
                    format='DD/MM/YYYY'
                    value={dayjs(doJ)}
                    onChange={newVal =>
                      setDoj(dayjs(newVal).tz('Asia/Kolkata').toDate())
                    }
                    slotProps={{
                      textField: {
                        error: !doJ && mandatoryIsEmpty
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='type-select-label'>Type</InputLabel>
                <GenericSelectCell
                  label={'Type'}
                  value={type}
                  key={'typeID'}
                  keyvalue={'typeName'}
                  itemName={'employeetype'}
                  onChange={newValue => setType(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='department-select-label'>Department</InputLabel>
                <GenericSelectCell
                  label={'Department'}
                  value={department}
                  key={'departmentID'}
                  keyvalue={'departmentName'}
                  itemName={'department'}
                  onChange={newValue => setDepartment(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='team-select-label'>Team</InputLabel>
                <GenericSelectCell
                  label={'Team'}
                  value={team}
                  key={'teamNo'}
                  keyvalue={'teamID'}
                  itemName={'team'}
                  onChange={newValue => setTeam(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='manager-select-label'>Manager</InputLabel>
                <ManagerSelect
                  value={managerID}
                  team={team}
                  onChange={newValue => setManagerID(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='designation-select-label'>
                  Designation
                </InputLabel>
                <GenericSelectCell
                  label={'Designation'}
                  value={designation}
                  key={'designationID'}
                  keyvalue={'designationName'}
                  itemName={'designation'}
                  onChange={newValue => setDesignation(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='office-select-label'>Office</InputLabel>
                <GenericSelectCell
                  label={'Office'}
                  value={office}
                  key={'locationID'}
                  keyvalue={'officeCode'}
                  itemName={'officelocation'}
                  onChange={newValue => setOffice(newValue)}
                  extra={['city']}
                />
                <FormControl fullWidth style={{ margin: '20px auto' }}>
                  <InputLabel id='holidaylist-select-label'>
                    Holidays
                  </InputLabel>
                  <HolidayListSelect
                    value={assignedHolidayList}
                    onChange={newValue => setAssignedHolidayList(newValue)}
                  />
                </FormControl>
              </FormControl>
              <FormControl fullWidth style={{ margin: '5px auto' }}>
                <InputLabel id='shift-select-label'>Shift</InputLabel>
                <GenericSelectCell
                  label={'Shift'}
                  value={shift}
                  key={'shiftID'}
                  keyvalue={'shiftName'}
                  itemName={'shift'}
                  extra={['shiftStartTime', 'shiftEndTime', 'shiftTimeZone']}
                  onChange={newValue => setShift(newValue)}
                />
              </FormControl>
              <FormControl fullWidth style={{ margin: '20px auto' }}>
                <InputLabel id='projects-select-label'>Projects</InputLabel>
                <ProjectSelect
                  value={assignedProjects}
                  onChange={newValue => setAssignedProjects(newValue)}
                />
              </FormControl>
            </>
          )}

          <Button
            variant='contained'
            className='button-gradient'
            onClick={handleSubmit}
          >
            <Save />
            Confirm edit
          </Button>
        </form>
      </DialogContent>}
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={progress}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
    </Dialog>
  )
}
