import React from 'react'
import { combineReducers } from '@reduxjs/toolkit'
import { attendanceAndLeaveAPIs } from './features/attendanceRTKFile'
import { holidaysAPIs } from './features/holidaysRTKFile'
import leaveReportViewStatusSlice from './features/leaveReportViewStatusSlice'
const rootReducer = combineReducers({
  [attendanceAndLeaveAPIs.reducerPath]: attendanceAndLeaveAPIs.reducer,
  [holidaysAPIs.reducerPath]:holidaysAPIs.reducer,
  leaveReportViewStatus:leaveReportViewStatusSlice.reducer
})
export default rootReducer
