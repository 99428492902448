import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, IconButton } from '@mui/material'
import LeaveReport from './LeaveReport'
import dayjs from 'dayjs'
import { CancelOutlined } from '@mui/icons-material'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { getAllLeaveReports } from '../../service/reportsService'
import { useSelector, useDispatch } from 'react-redux'
import { increment } from '../../reduxstore/features/leaveReportViewStatusSlice'

export default function OnLeaveOnCurrentDate ({ date, open, handleClose }) {
  const isAuthenticated = useIsAuthenticated()
  const user = useAuthUser()
  const [items, setItems] = useState([])
  const dispatch = useDispatch()
  const numberOfTimesViewed = useSelector(
    state => state.leaveReportViewStatus.value
  )
  async function fetchData () {
    try {
      const res = await getAllLeaveReports({
        startDate: dayjs(date ? date : new Date()).format('YYYY-MM-DD'),
        endDate: dayjs(date ? date : new Date()).format('YYYY-MM-DD')
      })
      const data = await res.json().then(dat => {
        if (dat.length > 0) {
          setItems(dat)
          dispatch(increment())
        }
      })
    } catch (error) {
      console.error(error)
      setItems([])
      handleClose()
    }
  }
  useEffect(() => {
    if (user() && isAuthenticated() && numberOfTimesViewed < 1) {
      fetchData()
    }
  }, [open])
  return items.length > 0 ? (
    <Dialog open={open} keepMounted={false}>
      <DialogTitle>
        Employees leave on {dayjs(date).format('DD/MM/YYYY')}
        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
          <CancelOutlined />
        </IconButton>
      </DialogTitle>
      <LeaveReport
      onlySearch={true}
        startDate={date}
        endDate={date}
        visibleColumns={{
          name: true,
          team: true,
          reason: true,
          approvedDate: false,
          approvedBy: false,
          lname: false,
          employeeID: false,
          leaveType: false,
          department: false,
          isApproved: false,
          leaveStartDate: false,
          leaveEndDate: false,
          shift: false,
          hoursOff: false,
          daysOff: false,
          office: false
        }}
      />
    </Dialog>
  ) : null
}
