/*For use of BeQiSoft Pvt Ltd. */

import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Typography,
  Button,
  TextField,
  InputLabel,
  IconButton,
  Backdrop,
  CircularProgress
} from '@mui/material'
import { ArrowForward, CancelOutlined } from '@mui/icons-material'
import { useAlert } from 'react-alert'
import { useAuthHeader, useAuthUser } from 'react-auth-kit'
import GenericSelectCell from '../Admin/AdminSelectCells/GenericSelectCell'
import { addLeaveRequest, calculateWeekends } from '../../service/leaveService'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en-in'
import {
  useAddLeaveMutation,
  useGetAttendanceDataQuery
} from '../../reduxstore/features/attendanceRTKFile'
import { useGetHolidaysDataQuery } from '../../reduxstore/features/holidaysRTKFile'
dayjs.extend(utc)
dayjs.extend(timezone)

export default function ApplyLeaves ({
  date: newDate,
  open,
  handleClose,
  setRefreshTable
}) {
  dayjs.extend(utc)

  const alert = useAlert()
  const [progress, setProgress] = useState(false)

  const resetState = () => {
    setProgress(false)
    setLeaveType(null)
    setLeaveStartDate(dayjs(initializeDates(newDate)))
    setLeaveEndDate(dayjs(initializeDates(newDate)))
    setHalfDayDates(null)
    setDaysOff(1)
    setHoursOff(defaultWorkingHours)
    setComments(null)
    setReason(null)
    setMandatorIsEmpty(false)
  }

  useEffect(() => {
    if (open) {
      resetState()
    }
  }, [open, newDate])

  const initializeDates = initialDate => {
    const defaultDate = dayjs(new Date()).tz('Asia/Kolkata').toDate()
    return initialDate != null
      ? dayjs(initialDate).tz('Asia/Kolkata').toDate()
      : defaultDate
  }

  const defaultWorkingHours = 8
  const [leaveType, setLeaveType] = useState(null)
  const [leaveStartDate, setLeaveStartDate] = useState(
    dayjs(initializeDates(newDate)).tz('Asia/Kolkata').toDate()
  )
  const [leaveEndDate, setLeaveEndDate] = useState(
    dayjs(initializeDates(newDate)).tz('Asia/Kolkata').toDate()
  )
  const [comments, setComments] = useState(null)
  const [halfDayDates, setHalfDayDates] = useState(null)
  const [reason, setReason] = useState(null)
  const [daysOff, setDaysOff] = useState(1)
  const [hoursOff, setHoursOff] = useState(defaultWorkingHours)
  const [holidays, setHolidays] = useState([])
  const [mandatoryIsEmpty, setMandatorIsEmpty] = useState(false)
  const [attendance, setAttendance] = useState([])
  const maxDays = 360
  const maxHours = 2880
  const user = useAuthUser()
  const auth = useAuthHeader()
  dayjs.extend(isBetween)
  dayjs.extend(isSameOrBefore)
  dayjs.extend(isSameOrAfter)
  const {
    data: holidaylist = [],
    isError: holidaysError,
    isLoading: holidaysLoading
  } = useGetHolidaysDataQuery({
    employeeID: user().employeeID,
    auth: auth()
  })
  const {
    data = attendance,
    isError,
    isLoading
  } = useGetAttendanceDataQuery({
    employeeID: user().employeeID,
    auth: auth()
  })
  const [addLeave, { data: leaveSuccess, isSuccess }] = useAddLeaveMutation()
  useEffect(() => {
    setAttendance(data)
    setHolidays(holidaylist)
  }, [])

  const handleSubmit = async () => {
    setProgress(true)
    const authEmployeeID = user().employeeID
    if (daysOff % 1 == 0.5) {
      if (halfDayDates == null) setHalfDayDates(dayjs(leaveEndDate))
    }
    const leaveRequestDetails = {
      leaveType: leaveType,
      reason: reason,
      leaveStartDate: dayjs(leaveStartDate).toDate(),
      leaveEndDate: dayjs(leaveEndDate).toDate(),
      daysOff: daysOff,
      hoursOff: hoursOff,
      comments: comments,
      isApproved: false,
      employeeIDf: authEmployeeID,
      halfDayDates:
        halfDayDates != null
          ? dayjs(dayjs(halfDayDates).toDate()).format('YYYY-MM-DD')
          : null
    }
    if (
      leaveRequestDetails.leaveStartDate != null &&
      leaveRequestDetails.leaveEndDate != null &&
      leaveRequestDetails.reason != null &&
      leaveRequestDetails.leaveType != null &&
      dayjs(dayjs(leaveStartDate)).isSameOrBefore(dayjs(leaveEndDate), 'day') &&
      IsValidDay(leaveRequestDetails.leaveStartDate) &&
      IsValidDay(leaveRequestDetails.leaveEndDate)
    ) {
      setMandatorIsEmpty(false)
      try {
        const res = await addLeave({
          updateFunction: addLeaveRequest,
          leaveDetails: leaveRequestDetails
        })
          .unwrap()
          .then(payload => alert.show(payload))
        return res
      } catch (error) {
        setProgress(false)
        alert.error(
          'Error in submission. Ensure dates are not overlapping with previous leaves'
        )
      } finally {
        setTimeout(() => {
          setComments('')
          setRefreshTable(true)
          handleClose()

          setProgress(false)
        }, 1000)
      }
    } else {
      setMandatorIsEmpty(true)
      setProgress(false)
    }
  }

  const IsValidDay = adate => {
    let NotWeekend = dayjs(adate).day() % 6
    let NotHoliday = !holidays?.find(hol =>
      dayjs(hol.holiday).isSame(dayjs(adate))
    )
    return NotWeekend != 0 && NotHoliday
  }
  const checkHolidaysBetween = (StartDate, EndDate) => {
    let holsInRange = holidays?.filter(hol => {
      return dayjs(hol.holiday).isBetween(StartDate, EndDate, 'day', '[]')
    })
    console.log('hols in range',holsInRange)
    return holsInRange
  }

  const calculateHoursOff = (daysOff, defaultWorkingHours) => {
    return daysOff * defaultWorkingHours
  }

  const updateEndDate = (startDate, daysOff) => {
    let dayCount = daysOff - 1
    let endDate = dayjs(startDate)
    while (dayCount > 0) {
      endDate = endDate.add(1, 'day')
      const isWeekend = [0, 6].includes(endDate.day())
      const isHoliday = holidays?.some(hol =>
        dayjs(hol.holiday).isSame(endDate, 'day')
      )
      if (isHoliday || isWeekend) {
        continue
      }
      dayCount--
    }
    return endDate
  }

  const handleDaysOffChange = newValue => {
    const newDaysOff = Math.max(Math.min(newValue, maxDays), 0.5)
    setDaysOff(newDaysOff)
    setLeaveEndDate(updateEndDate(leaveStartDate, newDaysOff))
    setHoursOff(calculateHoursOff(newDaysOff, defaultWorkingHours))
  }

  const handleHoursOffChange = newValue => {
    setHoursOff(Math.min(newValue, maxHours))
    handleHoursAndDays(newValue, 'hours')
  }

  const handleHoursAndDays = (newValue, unit) => {
    const calculatedDaysOff =
      dayjs(leaveEndDate).diff(leaveStartDate, 'days') -
      checkHolidaysBetween(leaveStartDate, leaveEndDate).length -
      calculateWeekends(leaveStartDate, leaveEndDate) +
      1
    const calculatedHoursOff = calculatedDaysOff * defaultWorkingHours
    if (
      dayjs(leaveStartDate).isBetween(leaveStartDate, leaveEndDate, 'day', '[]')
    )
      switch (unit) {
        case 'startdate':
          {
            if (
              dayjs(dayjs(newValue)).isSameOrBefore(dayjs(leaveEndDate), 'day')
            ) {
              setLeaveStartDate(dayjs(newValue).toDate())
              let newDaysOff =
                dayjs(leaveEndDate).diff(newValue, 'days') -
                checkHolidaysBetween(newValue, leaveEndDate).length -
                calculateWeekends(newValue, leaveEndDate) +
                1
              let newHoursOff = newDaysOff * defaultWorkingHours
              setDaysOff(newDaysOff)
              setHoursOff(newHoursOff)
            } else {
              setLeaveStartDate(dayjs(newValue).toDate())
              setLeaveEndDate(dayjs(newValue).toDate())
              setDaysOff(1)
              setHoursOff(defaultWorkingHours)
            }
          }
          break
        case 'enddate':
          {
            if (
              dayjs(dayjs(newValue)).isSameOrAfter(dayjs(leaveStartDate), 'day')
            ) {
              setLeaveEndDate(dayjs(newValue).toDate())
              let newDaysOff =
                dayjs(newValue).diff(leaveStartDate, 'days') -
                checkHolidaysBetween(leaveStartDate, newValue).length -
                calculateWeekends(leaveStartDate, newValue) +
                1
              let newHoursOff = newDaysOff * defaultWorkingHours
              setDaysOff(newDaysOff)
              setHoursOff(newHoursOff)
            } else {
              setLeaveStartDate(dayjs(newValue).toDate())
              setLeaveEndDate(dayjs(newValue).toDate())
              setDaysOff(1)
              setHoursOff(defaultWorkingHours)
            }
          }
          break
        case 'hours':
          {
            const extraHours = newValue - calculatedHoursOff
            if (newValue > calculatedHoursOff) {
              if (extraHours >= 4 && extraHours <= 6) {
                setDaysOff(calculatedDaysOff + 0.5)
                setLeaveEndDate(updateEndDate(leaveStartDate, daysOff + 1))
              } else if (extraHours >= defaultWorkingHours) {
                let reCalculatedDaysOff = newValue / defaultWorkingHours
                reCalculatedDaysOff =
                  reCalculatedDaysOff % 1 < 0.5
                    ? Math.floor(reCalculatedDaysOff)
                    : Math.ceil(reCalculatedDaysOff) + 1
                setDaysOff(reCalculatedDaysOff)
                setLeaveEndDate(
                  updateEndDate(leaveStartDate, Math.ceil(reCalculatedDaysOff))
                )
              }
            } else if (extraHours == 0) {
              setDaysOff(calculatedDaysOff)
              setLeaveEndDate(updateEndDate(leaveStartDate, calculatedDaysOff))
            } else {
              if (extraHours === -defaultWorkingHours / 2) {
                console.log('case 2')
                setDaysOff(daysOff - 0.5)
                if ((daysOff - 0.5) % 1 !== 0)
                  setLeaveEndDate(updateEndDate(leaveStartDate, daysOff))
              } else if (
                newValue >= defaultWorkingHours / 2 &&
                extraHours <= -defaultWorkingHours
              ) {
                let reCalculatedDaysOff = Math.ceil(
                  newValue / defaultWorkingHours
                )
                setDaysOff(reCalculatedDaysOff)
                setLeaveEndDate(
                  updateEndDate(leaveStartDate, reCalculatedDaysOff)
                )
              } else if (newValue <= defaultWorkingHours / 2) {
                setDaysOff(0.5)
                setHoursOff(defaultWorkingHours / 2)
                setLeaveEndDate(dayjs(leaveStartDate))
              }
            }
          }
          break
      }
  }

  return (
    <Dialog open={open} keepMounted={false}>
      <DialogTitle>
        <IconButton
          onClick={() => {
            setComments('')
            handleClose()
          }}
          sx={{ float: 'right' }}
        >
          <CancelOutlined />
        </IconButton>
        <Typography variant='h4'>Apply for leaves</Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          <p>Note:Overlapping leaves won't be reflected</p>
          <form className='div-centerstyleleft' noValidate>
            <FormControl
              required
              error={leaveType == null && mandatoryIsEmpty}
              fullWidth
              style={{ margin: '20px auto' }}
            >
              <InputLabel
                id='Leavetype-id'
                error={leaveType == null && mandatoryIsEmpty}
              >
                Leave type
              </InputLabel>
              <GenericSelectCell
                label={'Leave type'}
                value={leaveType}
                onChange={newVal => setLeaveType(newVal)}
                itemName={'leavetype'}
                key={'leaveID'}
                keyvalue={'leaveType'}
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='div-spaceToSides'>
                <DatePicker
                  format='DD/MM/YYYY'
                  label='Leave Start date'
                  value={dayjs(leaveStartDate)}
                  onChange={newVal => handleHoursAndDays(newVal, 'startdate')}
                  shouldDisableDate={date =>
                    dayjs(date).day() % 6 == 0 ||
                    (holidays.length > 0
                      ? holidays.some(h =>
                          dayjs(h.holiday).isSame(date, 'date')
                        )
                      : false) ||
                    (attendance.length > 0
                      ? attendance.some(
                          a => a.leave && dayjs(a.date).isSame(date)
                        )
                      : false)
                  }
                  slotProps={{
                    textField: {
                      readOnly: true
                    }
                  }}
                />
                <ArrowForward />
                <DatePicker
                  format='DD/MM/YYYY'
                  label='Leave End date'
                  value={dayjs(leaveEndDate)}
                  onChange={newVal => handleHoursAndDays(newVal, 'enddate')}
                  minDate={dayjs(leaveStartDate)}
                  shouldDisableDate={date =>
                    dayjs(date).day() % 6 == 0 ||
                    (holidays.length > 0
                      ? holidays.some(h => dayjs(h.holiday).isSame(date, 'date'))
                      : false) ||
                    (attendance.length > 0
                      ? attendance.some(
                          a => a.leave && dayjs(a.date).isSame(date)
                        )
                      : false)
                  }
                  slotProps={{
                    textField: {
                      readOnly: true
                    }
                  }}
                />
                <DatePicker
                  format='DD/MM/YYYY'
                  label='Half Day'
                  value={dayjs(halfDayDates)}
                  onChange={newVal => setHalfDayDates(dayjs(newVal))}
                  minDate={dayjs(leaveStartDate)}
                  maxDate={dayjs(leaveEndDate)}
                  sx={{ visibility: daysOff % 1 == 0.5 ? 'visible' : 'hidden' }}
                  shouldDisableDate={date =>
                    dayjs(date).day() % 6 == 0 ||
                    (holidays.length > 0
                      ? holidays.some(h => dayjs(h.holiday).isSame(date, 'date'))
                      : false) ||
                    (attendance.length > 0
                      ? attendance.some(
                          a => a.leave && dayjs(a.date).isSame(date)
                        )
                      : false)
                  }
                  slotProps={{
                    textField: {
                      readOnly: true
                    }
                  }}
                />
              </div>
            </LocalizationProvider>
            <TextField
              id='outlined-basic-days'
              label='Days'
              variant='filled'
              aria-required
              type='number'
              fullWidth
              value={daysOff}
              onChange={e => handleDaysOffChange(e.target.value)}
              style={{ margin: '10px auto' }}
              error={daysOff == null && mandatoryIsEmpty}
              InputProps={{
                inputProps: {
                  max: maxDays,
                  step: 0.5,
                  onKeyDown: event => {
                    event.preventDefault()
                  }
                }
              }}
            />

            <TextField
              id='outlined-basic-hours'
              label='Hours'
              variant='filled'
              aria-required
              fullWidth
              value={hoursOff}
              type='number'
              onChange={e => handleHoursOffChange(e.target.value)}
              style={{ margin: '20px auto' }}
              error={hoursOff == null && mandatoryIsEmpty}
              InputProps={{
                inputProps: {
                  max: maxHours,
                  step: 4,
                  onKeyDown: event => {
                    event.preventDefault()
                  }
                }
              }}
            />
            <FormControl
              required
              error={!reason && mandatoryIsEmpty}
              fullWidth
              style={{ margin: '20px auto' }}
            >
              <InputLabel
                id='Leavereason-id'
                error={!reason && mandatoryIsEmpty}
              >
                Reason
              </InputLabel>
              <GenericSelectCell
                label={'Reason'}
                value={reason}
                onChange={newVal => setReason(newVal)}
                itemName={'leavereasons'}
                key={'leaveReasonID'}
                keyvalue={'leaveReason'}
              />
            </FormControl>
            <TextField
              id='outlined-basic-comment'
              label='Comments'
              variant='outlined'
              fullWidth
              aria-required
              value={comments}
              defaultValue={null}
              multiline
              onChange={e => setComments(e.target.value)}
              style={{ margin: '20px auto' }}
            />
            <Button
              variant='contained'
              className='button-gradient'
              onClick={handleSubmit}
            >
              Submit Leave Request
            </Button>
          </form>
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={progress}
        >
          <CircularProgress></CircularProgress>
        </Backdrop>
      </DialogContent>
    </Dialog>
  )
}
