import React, { useState } from 'react'
import { Typography, Button, TextField, Paper } from '@mui/material'
import { Password, Person2 } from '@mui/icons-material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { fetchShortUserInfo } from '../../../service/addUserService'
import Add from '@mui/icons-material/Add'
import { FormControl, InputLabel } from '@mui/material'
import GenericSelectCell from '../AdminSelectCells/GenericSelectCell'
import { resetPassword } from '../../../service/userEditDetailsService'
import { useAlert } from 'react-alert'

export default function ResetPasswords () {
  const [formOpen, setFormOpen] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const alert = useAlert()
  const handleValueSubmit = async e => {
    if (e != null) {
      try {
        const req = await resetPassword(e.employeeID, e.Password)
        const reqText = await req.text()
        alert.show(reqText)
      } catch (error) {
        console.log(error)
      } finally {
        setRefreshTable(true)
      }
    } else {
      alert.show('Error resetting password')
    }
  }
  return (
    <>
      <div>
        <div className='div-spaceToSides'>
          <Typography variant='h4'>
            <Person2 />
            Reset Password manually for user
          </Typography>
        </div>
        <div className='div-paperStyle2'>
          <Formik
            initialValues={{
              employeeID: null,
              Password: null
            }}
            onSubmit={async (values, { resetForm, setFieldValue }) => {
              await handleValueSubmit(values)
              resetForm()
              setFieldValue('Password', '')
            }}
            validationSchema={yup.object({
              employeeID: yup.string().min(1).required('Required').trim(),
              Password: yup.string().min(8).required('Required').trim()
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                handleReset,
                isValid,
                submitCount
              } = props
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl
                    fullWidth
                    style={{ margin: '20px auto' }}
                    error={touched.employeeID && errors.employeeID}
                  >
                    <InputLabel
                      id='employee-select-label'
                      error={touched.employeeID && errors.employeeID}
                    >
                      Employee
                    </InputLabel>
                    <GenericSelectCell
                      label={'Employee*'}
                      value={values.employeeID}
                      key={'employeeID'}
                      keyvalue={'employeeID'}
                      itemName={'user'}
                      fetchData={fetchShortUserInfo}
                      extra={['name', 'lname']}
                      onChange={handleChange('employeeID')}
                    />
                  </FormControl>

                  <TextField
                    required
                    label='Password'
                    id='resetPasswordInput'
                    variant='outlined'
                    fullWidth
                    value={values.Password}
                    onChange={handleChange('Password')}
                    style={{ margin: '20px auto' }}
                    error={touched.Password && errors.Password}
                  />
                  {!isValid && submitCount > 0 && (
                    <div style={{ color: 'red', fontStyle: 'bold' }}>
                      Some fields may be empty or invalid
                    </div>
                  )}
                  <Button
                    variant='contained'
                    className='button-gradient'
                    type='submit'
                  >
                    <Add />
                    Reset Password
                  </Button>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  )
}
