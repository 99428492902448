import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: 0 }
//just keeps track if admin/manager has already viewed the condensed leave report
const leaveReportViewStatusSlice = createSlice({
  name: 'leaveReportViewStatus',
  initialState: initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    }
  }
})

export const { increment, decrement } = leaveReportViewStatusSlice.actions
export default leaveReportViewStatusSlice
